import React, { useRef, useEffect } from 'react';
import { TimelineLite } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import loadable from '@loadable/component';

const Globe = loadable(() => import('./Globe'));

import {
    ContentWrapper,
    HeroContentWrapper,
    HeroWrapper,
    GlobeWrapper,
    Heading1,
    Button
} from './styled';

const Hero: React.FC = () => {
    const heroContent = useRef(null);
    const description = useRef(null);
    const globe = useRef(null);

    useEffect(() => {
        const tl = new TimelineLite();

        tl.from(
            heroContent.current,
            {
                duration: 1,
                delay: 0.75,
                y: 50,
                opacity: 0,
                ease: 'power3.ease-in-out'
            },
            'start'
        )
            .from(
                description.current,
                {
                    duration: 1,
                    y: 10,
                    ease: 'power3.ease-in-out',
                    delay: 0.85
                },
                'start'
            )
            .from(globe.current, {
                duration: 0.5,
                opacity: 0,
                ease: 'power3.ease-out'
            });

        ScrollTrigger.matchMedia({
            '(min-width: 1400px)': function () {
                tl.to(globe.current, {
                    x: 350,
                    ease: 'power1.ease',
                    scrollTrigger: {
                        trigger: globe.current,
                        start: 'top 25%',
                        end: 'center 25%',
                        scrub: true
                    }
                });
            }
        });
    }, [heroContent, description, globe]);

    return (
        <HeroWrapper>
            <GlobeWrapper ref={globe}>
                <div />
                <Globe />
            </GlobeWrapper>

            <ContentWrapper>
                <HeroContentWrapper ref={heroContent}>
                    <Heading1>
                        Data is <span>music</span> to our ears
                    </Heading1>
                    <p ref={description}>
                        We use digital solutions to make sense of the vast amount of data music
                        rights holders and their partners are faced with these days.
                    </p>
                    {typeof window !== 'undefined' && window.innerWidth <= 1000 && (
                        <a href='#contact'>
                            <Button center>Contact us</Button>
                        </a>
                    )}
                </HeroContentWrapper>
            </ContentWrapper>
        </HeroWrapper>
    );
};

export default Hero;
