import React, { useEffect, useRef } from 'react';
import { gsap, TimelineLite } from 'gsap';

import {
    CommitmentWrapper,
    Heading2,
    Heading3,
    Heading4,
    TextSection,
    ValueCardsWrapper,
    CardIcon,
    Space
} from './styled';

// @ts-ignore
import SearchIcon from '../images/search.png';
// @ts-ignore
import LawIcon from '../images/law.png';
// @ts-ignore
import DollarIcon from '../images/dollar.png';

const Commitment: React.FC = () => {
    const textSection = useRef(null);
    const cardOne = useRef(null);
    const cardTwo = useRef(null);
    const cardThree = useRef(null);

    useEffect(() => {
        gsap.from(textSection.current, {
            y: 30,
            opacity: 0,
            duration: 0.5,
            ease: 'power3.ease-in-out',
            scrollTrigger: {
                trigger: textSection.current,
                start: 'top 65%'
            }
        });

        const tl = new TimelineLite({
            scrollTrigger: {
                trigger: cardOne.current,
                start: 'top 65%'
            }
        });

        tl.from(
            cardOne.current,
            {
                y: 30,
                scale: 1.05,
                opacity: 0,
                duration: 0.3,
                ease: 'power3.ease-in-out'
            },
            'values'
        )
            .from(
                cardTwo.current,
                {
                    y: 40,
                    scale: 1.05,
                    opacity: 0,
                    duration: 0.3,
                    delay: 0.2,
                    ease: 'power3.ease-in-out'
                },
                'values'
            )
            .from(
                cardThree.current,
                {
                    y: 50,
                    scale: 1.05,
                    opacity: 0,
                    duration: 0.3,
                    delay: 0.4,
                    ease: 'power3.ease-in-out'
                },
                'values'
            );
    }, [textSection, cardOne, cardTwo, cardThree]);

    return (
        <Space>
            <CommitmentWrapper>
                <TextSection ref={textSection}>
                    <Heading3>Our commitment</Heading3>
                    <Heading2>What we strive for</Heading2>
                    <p>
                        We shed light on the matter with Data Science by aggregating, standardising
                        and analysing data from different sources to provide meaningful insights -
                        whether this relates to royalty statements, actual stream counts or auditing
                        procedures.
                    </p>
                </TextSection>

                <ValueCardsWrapper>
                    <div ref={cardOne}>
                        <CardIcon>
                            <img src={SearchIcon} alt='Transparency' />
                        </CardIcon>
                        <Heading4>More transparency</Heading4>
                        <p>Data should be accessible and readable for better informed decisions.</p>
                    </div>
                    <div ref={cardTwo}>
                        <CardIcon>
                            <img src={LawIcon} alt='Empowerment' />
                        </CardIcon>
                        <Heading4>Empowerment</Heading4>
                        <p>
                            The utilisation of rights should largely be determined by their owners.
                        </p>
                    </div>
                    <div ref={cardThree}>
                        <CardIcon>
                            <img src={DollarIcon} alt='Fair pay' />
                        </CardIcon>
                        <Heading4>Fair pay</Heading4>
                        <p>
                            Music is a valuable asset everyone along the value chain should
                            participate in.
                        </p>
                    </div>
                </ValueCardsWrapper>
            </CommitmentWrapper>
        </Space>
    );
};

export default Commitment;
