import React, { useState, useReducer, useRef, useEffect } from 'react';
import { TimelineLite } from 'gsap';
import FadeLoader from 'react-spinners/FadeLoader';

import Footer from './Footer';

import {
    ContentWrapper,
    Heading2,
    TextInput,
    TextArea,
    ContactWrapper,
    Select,
    Button,
    Form,
    LoadingWrapper,
    SuccessWrapper,
    Space
} from './styled';

const initialState = {
    name: '',
    email: '',
    role: 'Artist',
    subject: '',
    message: ''
};

const reducer = (state: any, { field, value }) => ({
    ...state,
    [field]: value
});

const Contact: React.FC = () => {
    const textSection = useRef(null);
    const form = useRef(null);

    useEffect(() => {
        const tl = new TimelineLite({
            scrollTrigger: {
                trigger: textSection.current,
                start: 'top 75%'
            }
        });

        tl.from(
            textSection.current,
            {
                y: 30,
                opacity: 0,
                duration: 0.5,
                ease: 'power3.ease-in-out'
            },
            'contact'
        ).from(
            form.current,
            {
                y: 30,
                opacity: 0,
                duration: 0.5,
                ease: 'power3.ease-in-out',
                delay: 0.2
            },
            'contact'
        );
    }, [textSection, form]);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [success, setSuccess] = useState<boolean>(false);
    const [state, dispatch] = useReducer(reducer, initialState);

    const onChange = (e: any) => dispatch({ field: e.target.name, value: e.target.value });

    const onSubmit = (e: any) => {
        e.preventDefault();

        setError(null);

        if (
            name.length === 0 ||
            email.length === 0 ||
            role.length === 0 ||
            subject.length === 0 ||
            message.length === 0
        ) {
            setError('Please fill out every field before submitting the form');
            return;
        }

        setLoading(true);

        fetch(process.env.GATSBY_CONTACT_ENDPOINT, {
            method: 'POST',
            body: JSON.stringify(state),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                if (!res.ok) throw new Error();

                setLoading(false);
                setSuccess(true);
            })
            .catch(() => {
                setLoading(false);
                setError('An unexpected error occurred, please try again.');
            });
    };

    const { name, email, role, subject, message } = state;

    return (
        <ContactWrapper>
            <Space>
                <ContentWrapper>
                    <div ref={textSection}>
                        <Heading2 center>Get in touch</Heading2>
                        <p>
                            Tell us a bit about who you are and what challenge you are facing and we
                            will get back to you asap.
                        </p>
                    </div>

                    {loading ? (
                        <LoadingWrapper>
                            <FadeLoader height={15} width={5} radius={2} margin={2} color='white' />
                        </LoadingWrapper>
                    ) : success ? (
                        <SuccessWrapper>
                            <p>Thanks for your message!</p>
                        </SuccessWrapper>
                    ) : (
                        <>
                            <Form onSubmit={onSubmit} ref={form}>
                                <div>
                                    <label htmlFor='name'>Name</label>
                                    <TextInput
                                        type='text'
                                        id='name'
                                        name='name'
                                        placeholder='Name'
                                        value={name}
                                        onChange={onChange}
                                    />
                                </div>
                                <div>
                                    <label htmlFor='role'>Role</label>
                                    <Select id='role' name='role' value={role} onChange={onChange}>
                                        <option value='Artist'>Artist</option>
                                        <option value='Producer'>Producer</option>
                                        <option value='Manager'>Manager</option>
                                        <option value='Label'>Label</option>
                                        <option value='Publisher'>Publisher</option>
                                        <option value='Other'>Other</option>
                                    </Select>
                                </div>

                                <div>
                                    <label htmlFor='email'>Email</label>
                                    <TextInput
                                        type='email'
                                        id='email'
                                        name='email'
                                        placeholder='Email'
                                        value={email}
                                        onChange={onChange}
                                    />
                                </div>
                                <div>
                                    <label htmlFor='subject'>Subject</label>
                                    <TextInput
                                        type='text'
                                        id='subject'
                                        name='subject'
                                        placeholder='Subject'
                                        value={subject}
                                        onChange={onChange}
                                    />
                                </div>

                                <div>
                                    <label htmlFor='message'>Message</label>
                                    <TextArea
                                        id='message'
                                        name='message'
                                        placeholder='Message'
                                        value={message}
                                        onChange={onChange}
                                        rows={6}
                                    />
                                </div>

                                <div>
                                    <Button>Send message</Button>
                                </div>
                            </Form>

                            {error && (
                                <LoadingWrapper>
                                    <p>{error}</p>
                                </LoadingWrapper>
                            )}
                        </>
                    )}
                </ContentWrapper>
            </Space>

            <Footer />
        </ContactWrapper>
    );
};

export default Contact;
