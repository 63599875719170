import * as React from "react"

import Navbar from '../components/Navbar';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Challenge from '../components/Challenge';
import Reality from '../components/Reality';
import Commitment from '../components/Commitment';
import Services from '../components/Services';
import About from '../components/About';
import Contact from '../components/Contact';
import Banner from "../components/Banner";

const Home = () => {
    return (
        <Layout>
            {/*<Banner/>*/}

            <Navbar />

            <section id='hero'>
                <Hero />
            </section>

            <section id='challenge'>
                <Challenge />
                <Reality />
            </section>

            <section id='commitment'>
                <Commitment />
            </section>

            <section id='services'>
                <Services />
            </section>

            <section id='about'>
                <About />
            </section>

            <section id='contact'>
                <Contact />
            </section>
        </Layout>
    );
};

export default Home;
