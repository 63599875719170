import React, { useEffect, useRef } from 'react';
import { TimelineLite } from 'gsap';

import { Heading2, AboutWrapper, TextSection, Space } from './styled';

const About: React.FC = () => {
    const heading = useRef(null);
    const text = useRef(null);

    useEffect(() => {
        const tl = new TimelineLite({
            scrollTrigger: {
                trigger: heading.current,
                start: 'top 75%'
            }
        });

        tl.from(
            heading.current,
            {
                y: 30,
                opacity: 0,
                duration: 0.5,
                ease: 'power3.ease-in-out'
            },
            'about'
        ).from(
            text.current,
            {
                y: 30,
                opacity: 0,
                duration: 0.5,
                ease: 'power3.ease-in-out',
                delay: 0.2
            },
            'about'
        );
    }, [heading, text]);

    return (
        <AboutWrapper>
            <Space>
                <TextSection center>
                    <Heading2 center ref={heading}>
                        The people behind <span>Takkt</span>
                    </Heading2>
                    <div ref={text}>
                        <p>
                            Takkt grew out of a technology firm specialised in high-tech innovation.
                            Over the past 10 years, we have helped large corporates to embrace
                            disruptive technologies and develop cutting-edge, digital products.
                            Based on this activity, we have built up extensive know-how in the field
                            of software engineering, machine learning, and data science.
                        </p>
                        <p>
                            With longstanding relationships in the music business, we have observed
                            the increasing power and powerlessness of a changing industry
                            first-hand. It is for that reason we decided to take up the challenge
                            and bring light into the darkness.
                        </p>
                        <p>
                            Our team is made up of Data Scientists, Developers, Designers, Music
                            Experts — and each of us plays an instrument.
                        </p>
                    </div>
                </TextSection>
            </Space>
        </AboutWrapper>
    );
};

export default About;
