import React, { useEffect, useRef } from 'react';
import { TimelineLite } from 'gsap';

// @ts-ignore
import OceanVideo from '../images/ocean.mp4';
// @ts-ignore
import OceanImage from '../images/ocean.png';

import {
    ContentWrapper,
    Heading2,
    Heading3,
    TextSection,
    RealityWrapper,
    OceanWrapper,
    Space
} from './styled';

const Reality: React.FC = () => {
    const textSection = useRef(null);

    useEffect(() => {
        const tl = new TimelineLite({
            scrollTrigger: {
                trigger: textSection.current,
                start: 'top 65%'
            }
        });

        tl.from(textSection.current, {
            y: 30,
            opacity: 0,
            duration: 0.5,
            ease: 'power3.ease-in-out'
        });
    }, [textSection]);

    return (
        <RealityWrapper>
            <OceanWrapper>
                <div />
                <video preload='true' autoPlay loop muted poster={OceanImage}>
                    <source src={OceanVideo} type='video/mp4' />
                </video>
            </OceanWrapper>

            <Space>
                <ContentWrapper>
                    <TextSection alignRight ref={textSection}>
                        <Heading3>The new reality</Heading3>
                        <Heading2>An ocean of data</Heading2>
                        <p>
                            The steady growth and digitalisation of the music business as a whole
                            has produced an overwhelming and heterogeneous amount of data, which
                            makes it increasingly difficult to understand how music is being used.
                            Yet, it offers great chances.
                        </p>
                    </TextSection>
                </ContentWrapper>
            </Space>
        </RealityWrapper>
    );
};

export default Reality;
