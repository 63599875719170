import React, { useEffect, useRef } from 'react';
import { TimelineLite } from 'gsap';

import {
    ContentWrapper,
    ChallengeWrapper,
    Heading2,
    Heading3,
    TextSection,
    NumbersSection,
    Space
} from './styled';

const Challenge: React.FC = () => {
    const textSection = useRef(null);
    const numberOne = useRef(null);
    const numberTwo = useRef(null);
    const numberThree = useRef(null);

    useEffect(() => {
        const tl = new TimelineLite({
            scrollTrigger: {
                trigger: textSection.current,
                start: 'top 65%'
            }
        });

        tl.from(
            textSection.current,
            {
                y: 30,
                duration: 0.5,
                opacity: 0,
                ease: 'power3.ease-in-out'
            },
            'challenge'
        )
            .from(
                numberOne.current,
                {
                    y: 30,
                    opacity: 0,
                    ease: 'power3.ease-in-out',
                    delay: 0.2
                },
                'challenge'
            )
            .from(
                numberTwo.current,
                {
                    y: 40,
                    opacity: 0,
                    ease: 'power3.ease-in-out',
                    delay: 0.3
                },
                'challenge'
            )
            .from(
                numberThree.current,
                {
                    y: 50,
                    opacity: 0,
                    ease: 'power3.ease-in-out',
                    delay: 0.4
                },
                'challenge'
            );
    }, [textSection, numberOne, numberTwo, numberThree]);

    return (
        <ChallengeWrapper>
            <Space>
                <ContentWrapper>
                    <TextSection ref={textSection}>
                        <Heading3>The challenge</Heading3>
                        <Heading2>Growing industry</Heading2>
                        <p>
                            Over the past decade, the music industry has seen an economic revival,
                            most notably through the rise of digital streaming services, which now
                            make up most of the music consumption worldwide.
                        </p>

                        <NumbersSection>
                            <div ref={numberOne}>
                                <span>$21.6B+</span>
                                <p>Global recorded music revenue</p>
                            </div>

                            <div ref={numberTwo}>
                                <span>434M+</span>
                                <p>Paid subscription accounts</p>
                            </div>

                            <div ref={numberThree}>
                                <span>62%</span>
                                <p>Of revenue comes from streaming</p>
                            </div>
                        </NumbersSection>
                    </TextSection>
                </ContentWrapper>
            </Space>
        </ChallengeWrapper>
    );
};

export default Challenge;
