import * as React from "react";
import {useEffect, useRef, useState} from 'react';
import { gsap, TimelineLite } from 'gsap';

import {
    ServicesWrapper,
    TextSection,
    Heading2,
    Heading3,
    Heading4,
    ServiceCardsWrapper,
    ServiceCard,
    ServicesToggle,
    ValueCardsWrapper,
    CardIcon,
    Space
} from './styled';

// @ts-ignore
import DiscographyIcon from '../images/discography.svg';
// @ts-ignore
import ApprovalsIcon from '../images/approvals.svg';
// @ts-ignore
import FilesIcon from '../images/files.svg';
// @ts-ignore
import ControlScreenshot from '../images/control-screenshot.png';
// @ts-ignore
import MonitorScreenshot from '../images/monitor-screenshot.png';
// @ts-ignore
import InsightScreenshot from '../images/insight-screenshot.png';
// @ts-ignore
import StatementsScreenshot from '../images/statements-screenshot.png';
// @ts-ignore
import ControlIcon from '../images/control.svg';
// @ts-ignore
import MonitorIcon from '../images/monitor.svg';
// @ts-ignore
import InsightIcon from '../images/insight.svg';
// @ts-ignore
import StatementsIcon from '../images/service.svg';

const Services: React.FC = () => {
    const [activeService, setActiveService] = useState<number>(0);

    const textSection = useRef(null);
    const cardOne = useRef(null);
    const cardTwo = useRef(null);
    const cardThree = useRef(null);
    const cardFour = useRef(null);
    const cardFive = useRef(null);
    const cardSix = useRef(null);
    const cardSeven = useRef(null);
    const cardEight = useRef(null);

    useEffect(() => {
        gsap.from(textSection.current, {
            y: 30,
            opacity: 0,
            duration: 0.5,
            ease: 'power3.ease-in-out',
            scrollTrigger: {
                trigger: textSection.current,
                start: 'top 65%'
            }
        });

        gsap.from(cardOne.current, {
            y: 30,
            opacity: 0,
            duration: 0.3,
            ease: 'power3.ease-in-out',
            scrollTrigger: {
                trigger: cardOne.current,
                start: 'top 65%'
            }
        });

        gsap.from(cardTwo.current, {
            y: 40,
            opacity: 0,
            duration: 0.3,
            ease: 'power3.ease-in-out',
            scrollTrigger: {
                trigger: cardTwo.current,
                start: 'top 65%'
            }
        });

        gsap.from(cardThree.current, {
            y: 50,
            opacity: 0,
            duration: 0.3,
            ease: 'power3.ease-in-out',
            scrollTrigger: {
                trigger: cardThree.current,
                start: 'top 55%'
            }
        });

        gsap.from(cardFour.current, {
            y: 40,
            opacity: 0,
            duration: 0.3,
            ease: 'power3.ease-in-out',
            scrollTrigger: {
                trigger: cardFour.current,
                start: 'top 65%'
            }
        });

        gsap.from(cardFive.current, {
            y: 50,
            opacity: 0,
            duration: 0.3,
            ease: 'power3.ease-in-out',
            scrollTrigger: {
                trigger: cardFive.current,
                start: 'top 55%'
            }
        });

        const tl = new TimelineLite({
            scrollTrigger: {
                trigger: cardSix.current,
                start: 'top 65%'
            }
        });

        tl.from(
            cardSix.current,
            {
                y: 30,
                scale: 1.05,
                opacity: 0,
                duration: 0.3,
                ease: 'power3.ease-in-out'
            },
            'ecosystem'
        )
            .from(
                cardSeven.current,
                {
                    y: 40,
                    scale: 1.05,
                    opacity: 0,
                    duration: 0.3,
                    delay: 0.2,
                    ease: 'power3.ease-in-out'
                },
                'ecosystem'
            )
            .from(
                cardEight.current,
                {
                    y: 50,
                    scale: 1.05,
                    opacity: 0,
                    duration: 0.3,
                    delay: 0.4,
                    ease: 'power3.ease-in-out'
                },
                'ecosystem'
            );
    }, [
        textSection,
        cardOne,
        cardTwo,
        cardThree,
        cardFour,
        cardFive,
        cardSix,
        cardSeven,
        cardEight
    ]);

    const services = [
        {
            title: 'Control',
            category: 1,
            text1: 'Technology-supported audit',
            text2: 'Control is an automated auditing software built on the latest Data Science and Machine Learning technology. The core principle is to examine settlement data according to individual contractual terms, making use of forensic algorithms. Control can be used for full-fledged audits or as an instrument for regular controlling purposes.',
            text3: 'Data benchmarking for the disclosure of systemic faults and inconsistencies.',
            text4: 'Fast, complete, precise. With a contract compliant set-up.',
            screenshot: ControlScreenshot,
            icon: ControlIcon
        },
        {
            title: 'Monitor',
            category: 2,
            text1: 'Real-time tracking',
            text2: 'The use of copyrighted material, most notably due to the rise of digital streaming services, has increased exponentially in recent years. With Monitor, we aggregate and consolidate global usage data to provide an inside view of the actual music consumption in the digital domain, making use of advanced audio fingerprinting systems.',
            text3: 'Automated aggregation of daily stream counts from leading DSPs including YouTube.',
            text4: 'Neural audio fingerprinting for the detection of recordings in user-generated content.',
            screenshot: MonitorScreenshot,
            icon: MonitorIcon
        },
        {
            title: 'Insight',
            category: 1,
            text1: 'Data dashboard',
            text2: 'Royalty statements usually come as hundreds of thousands of rows of Excel. We use Data Science to make them understandable, comparable, and reviewable. Insight is the standardised, cross-settlement visualisation of settlement data based on key metrics.',
            text3: 'Readable, understandable, transparent. Across settlements.',
            text4: 'In-depth analysis of average per-stream rates, Digital Allocations, and more.',
            screenshot: InsightScreenshot,
            icon: InsightIcon
        },
        {
            title: 'Statements',
            category: 2,
            text1: 'Backoffice-as-a-service',
            text2: 'Licensors are obliged to give right holders such as Featured Artists a share of the royalty revenue in accordance with their contractually agreed rates. We fully automatise the process and provide Service Statements for each settlement period in consideration of individual terms and account balances.',
            text3: 'Automatic generation of tax conform royalty statements.',
            text4: 'Easy management of billings, advanced payments and open balances.',
            screenshot: StatementsScreenshot,
            icon: StatementsIcon
        }
    ];

    return (
        <Space small>
            <ServicesWrapper>
                <TextSection ref={textSection}>
                    <Heading3>Our services</Heading3>
                    <Heading2>A fully integrated suite</Heading2>
                    <p>
                        With Takkt we provide a digital toolset to face an increasingly digitalised
                        industry. Each application is interlocked and designed to either optimise
                        processes or create additional value.
                    </p>
                </TextSection>

                <Space small>
                    <ServicesToggle active={activeService}>
                        {services.map((service, index) => (
                            <li key={index} onClick={() => setActiveService(index)}>
                                {service.title}
                            </li>
                        ))}
                    </ServicesToggle>
                </Space>

                <ServiceCardsWrapper>
                    <ServiceCard category={services[activeService].category} large ref={cardOne}>
                        <Heading4>{services[activeService].text1}</Heading4>
                        <p>{services[activeService].text2}</p>

                        <img
                            src={services[activeService].screenshot}
                            alt='Screenshot of the service'
                        />
                    </ServiceCard>

                    <ServiceCard ref={cardTwo}>
                        <span>{services[activeService].text3}</span>
                    </ServiceCard>

                    <ServiceCard ref={cardThree}>
                        <span>{services[activeService].text4}</span>
                    </ServiceCard>
                </ServiceCardsWrapper>

                <Space small>
                    <Heading2 center>Powered by a smart ecosystem.</Heading2>
                </Space>

                <ValueCardsWrapper>
                    <div ref={cardSix}>
                        <CardIcon>
                            <img src={DiscographyIcon} alt='Discography' />
                        </CardIcon>
                        <Heading4>Disography</Heading4>
                        <p>Complete and uniquely identifiable record of an artist's repertoire.</p>
                    </div>

                    <div ref={cardSeven}>
                        <CardIcon>
                            <img src={ApprovalsIcon} alt='Approvals' />
                        </CardIcon>
                        <Heading4>Approvals</Heading4>
                        <p>Centralised management of synch, price, and compilation requests.</p>
                    </div>

                    <div ref={cardEight}>
                        <CardIcon>
                            <img src={FilesIcon} alt='Files' />
                        </CardIcon>
                        <Heading4>Files</Heading4>
                        <p>Secure file repository of contracts and settlement data.</p>
                    </div>
                </ValueCardsWrapper>
            </ServicesWrapper>
        </Space>
    );
};

export default Services;
